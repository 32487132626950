<template>
  <interactiveToast
    :key="showIFrameWarning"
    title="Error!"
    body="GeoGrid's official website is https://www.geogridgame.com/ It looks like you're playing on a website that has stolen it! These websites steal revenue from game creators by re-hosting their games and overlaying their own ads. Play GeoGrid on geogridgame.com, and you'll always have the latest version, fewer ads and help support the creators (i.e. us)! Thanks, - Teuteuf Team" :show="showIFrameWarning"
    :primaryButton="{
      label: 'Go There',
      onClick: goThere
    }" />
</template>

<script>
import { onMounted, ref } from 'vue';
import interactiveToast from './interactiveToast.vue';

export default {
  components: {
    interactiveToast,
  },
  setup() {
    const showIFrameWarning = ref(false);

    const goThere = () => {
      window.open('https://www.geogridgame.com/?utm_campaign=iframe_popup')
    };

    const inIFrame = () => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }

    onMounted(() => {
      if (inIFrame()) {
        showIFrameWarning.value = true;
        window.gtag && window.gtag('event', 'iframe_popup');
      }
    });

    return {
      showIFrameWarning,
      goThere,
    };
  },
};
</script>
